import React, { useState, useEffect, useRef } from 'react'
import { Input, Upload, message } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface.d'
import { compact } from 'lodash'

import { formatErrors } from 'Utils'
import { uploadProductImage } from 'Services/products'
import './DynamicContent.scss'
import { useStoreActions, useStoreState } from 'easy-peasy'

export type UploadProps = {
  onChange: (images: string[] | string | undefined) => void
  value: string[] | undefined
  fileType: string[]
  fileCount?: number
  fieldName?: string
}

const isValidFile = (validMimeTypes: string[], fileType: string) => {
  return validMimeTypes.some((type) => fileType.includes(type))
}

const UploadField: React.FC<UploadProps> = ({
  onChange: setFiles,
  value,
  fileCount = 100,
  fileType,
  fieldName,
}) => {
  const { selected_dynamic_param, content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((state: any) => state.campaignModule)
  const [inputHasValue, setInputHasValue] = useState(false)
  const firstRun = useRef(true)
  const [fileList, setFileList] = useState<UploadFile[]>(
    value ? value.map((file) => ({ uid: file, name: file, status: 'done', url: file })) : []
  )

  const handleUploadFile = async (info: UploadRequestOption) => {
    try {
      const file: any = info.file
      if (fileType.length > 0) {
        if (!isValidFile(fileType, file.type)) {
          throw new Error(`Only ${fileType.join(', ')} files are allowed`)
        }
      }

      const resp = await uploadProductImage({
        image: info.file,
        config: {
          onUploadProgress: (event) => {
            info.onProgress &&
              info.onProgress({ ...event, percent: (event.loaded / event.total) * 100 })
          },
        },
      })
      const xhr = new XMLHttpRequest()
      info.onSuccess && info.onSuccess(resp.data[0].url, xhr)
    } catch (err: any) {
      // console.log('err', err?.toJSON())
      err && message.error(err.message || 'Cannot upload file, try another one')
      info.onError &&
        info.onError({
          name: 'error',
          message: err.message || 'Cannot upload file, try another one',
        })
    }
  }

  const onChange = (info: UploadChangeParam) => {
    info.fileList.forEach((file: UploadFile) => {
      if (file.response) file.url = file.response
      if (file.status === 'error') file.thumbUrl = ''
      if (file.status == null) file.status = 'error'
    })
    setFileList(info.fileList)
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    setFiles(compact(fileList.map((file) => file.url)))
  }, [fileList, fileCount])

  const renderMeat = () => {
    return (
      <Upload.Dragger
        fileList={fileList}
        defaultFileList={fileList}
        customRequest={handleUploadFile}
        onChange={onChange}
        listType="picture-card"
        beforeUpload={(file) => {
          const isLt100M = file.size / 1024 / 1024 < 100 // Giới hạn 100MB
          if (!isLt100M) {
            message.error('File quá lớn, vui lòng tải lên file nhỏ hơn 100MB')
          }
          return isLt100M
        }}
      >
        <p className="ant-upload-drag-icon">
          <FileImageOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload or Input below</p>
      </Upload.Dragger>
    )
  }

  return (
    <div className="CampaignEmail-ImageUpload">
      <div style={{ display: inputHasValue ? 'none' : 'block' }}>{renderMeat()} </div>
      <div>
        <Input
          placeholder="Enter URL"
          value={content?.[fieldName as string] || value || ''}
          onChange={(e) => {
            setFiles(e.target.value as any)
            setInputHasValue(!!e.target.value)
            setState({
              key: 'selected_dynamic_param',
              value: { key: fieldName, value: e.target.value, position: e?.target?.selectionStart },
            })
          }}
          onClick={(e) => {
            setState({
              key: 'selected_dynamic_param',
              value: {
                ...selected_dynamic_param,
                position: e.currentTarget.selectionStart,
              },
            })
          }}
          onFocus={(e) => {
            setState({
              key: 'selected_dynamic_param',
              value: {
                key: fieldName,
                value: e.currentTarget.value,
                position: e.currentTarget.selectionStart,
              },
            })
          }}
        ></Input>
      </div>
    </div>
  )
}

export default UploadField
