import React, { useEffect } from 'react'

import Form from './Form'
import Preview from 'Components/PreviewChannels/Preview'
import { useStoreState, useStoreActions } from 'easy-peasy'
import EmailTabs from 'Components/CJChannels/Email'

import './index.scss'
import DynamicParams from './Form/DynamicParams'
import { Button, Tooltip } from 'antd'
import personalize from 'Assets/images/personalized.png'
import { useKeyboardShortcut } from './useKeyboardShortcut'
export type EmailType = 'kariba' | 'sendgrid'

const Content = () => {
  const { selected_placement, content, toggleDynamicParam } = useStoreState(
    (state) => state.campaignModule
  )
  const { setContent, setState, clearContent } = useStoreActions(
    (action: any) => action.campaignModule
  )

  useKeyboardShortcut({
    keyCombo: 'Meta+j' || 'Ctrl+j',
    onKeyPressed: () => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam }),
  })
  if (selected_placement.code === 'email')
    return (
      <EmailTabs
        values={content}
        isCampaign={true}
        withModal={false}
        btnSubmit={false}
        breadcrumbVisble={false}
        clearValue={clearContent}
        setValue={(name, value) => {
          setContent({ key: name, value })
        }}
      ></EmailTabs>
    )
  return (
    <div className="w-full flex gap-4" id="CampaignContent">
      <div
        className={`${selected_placement.code === 'kpos' ? 'w-1/3' : toggleDynamicParam ? 'w-2/5' : 'w-1/2'
          } bg-gray_1 p-4 rounded`}
      >
        <Form />
      </div>
      <div
        className={`${selected_placement.code === 'kpos' ? 'w-2/3' : toggleDynamicParam ? 'w-2/5' : 'w-1/2'
          } bg-gray_1 p-4 rounded`}
      >
        <Preview />
      </div>
      {toggleDynamicParam && (
        <div
          className=" bg-white w-1/5 rounded transition-all duration-500 sticky top-8"
          style={{ width: '287px' }}
        >
          <DynamicParams
            extraParams={[
              {
                group_code: 'ai_params',
                group_name: 'AI Params',
                parameters: content?.params?.map((item: any) => {
                  return {
                    parameter_code: item?.parameter_code,
                    format: item?.format,
                  }
                }),
              },
            ]}
          />
        </div>
      )}
      {!toggleDynamicParam && (
        <Tooltip
          title={
            <div className="flex gap-1 flex-col w-max">
              <div className=" font-semibold text-sm">Personalize</div>
              <div className=" text-sm">Add personalized parameters to your message</div>
              <div className=" text-sm text-gray-300">Cmd + J (Mac) / Ctrl + J (Window)</div>
            </div>
          }
          overlayStyle={{ width: 'max-content' }}
          overlayInnerStyle={{ width: 'max-content' }}
          overlayClassName="w-max PersonalizeButtonTooltip"
          placement="top"
        >
          <div
            className="PersonalizeButton rounded-full cursor-pointer"
            onClick={() => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam })}
          >
            <img src={personalize} alt="personalize" />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default Content
