export const OnelifeAppPushSchema = [
  {
    name: 'title',
    type: 'dynamic_content',
    label: 'Title',
    isRequired: false,
  },
  {
    name: 'content',
    type: 'dynamic_content',
    label: 'Content',
    isRequired: false,
  },
  {
    name: 'image',
    type: 'image',
    label: 'Image',
    isRequired: false,
  },
  {
    name: 'large_image',
    type: 'image',
    label: 'Large Image',
    isRequired: false,
  },
  {
    name: 'icon',
    type: 'image',
    label: 'Icon',
  },
  {
    name: 'cta',
    type: 'button_selects_deeplink',
    label: 'Action buttons',
    require: true,
    options: [
      {
        type: 'primary',
        label: 'Primary',
        sub_options: [
          {
            code: 'coupon',
            name: 'Coupon',
          },
          {
            code: 'point_history',
            name: 'Point history',
          },
        ],
      },
      {
        type: 'secondary',
        label: 'Secondary',
        sub_options: [
          {
            code: 'coupon',
            name: 'Coupon',
          },
          {
            code: 'point_history',
            name: 'Point history',
          },
        ],
      },
    ],
  },

  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
    defaultValue: 'ol',
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
    defaultValue: 'app_notif',
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]

export default OnelifeAppPushSchema

export type OnelifeAppPushParams = {
  title: string
  content: string
  image: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
  icon: string
}

export const AI_SCHEMAS = [
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
    defaultValue: 'ol',
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
    defaultValue: 'app_notif',
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]
