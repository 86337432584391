import React, { useMemo } from 'react'
import { Select, Input, Checkbox } from 'antd'

import DependentSelects from './DependentSelects/index'
import ImageField from './Image'
import ProductField from './StoreFarmingForm/Product'
import DynamicContent from './DynamicContent'
import ArrayItem from './ArrayItem'
import ButtonSelects from './ButtonSelects'
import ButtonSelectsDeepLink from './ButtonSelectsDeeplink'
import { useStoreState } from 'easy-peasy'
import UploadField from './Upload'

const { TextArea } = Input

export type InputType =
  | 'image'
  | 'text_area'
  | 'string'
  | 'select'
  | 'dependent_selects'
  | 'button_selects'
  | string

export type FormItemType = {
  name?: string
  value: any
  defaultValue?: string
  onChange: (name: string, value?: any) => void
  options?: any[]
  placeholder?: string
  type: InputType
  dynamicParams?: any
  fileType?: string[]
}

const FormItem: React.FC<FormItemType> = ({
  name,
  value,
  onChange,
  type,
  options,
  placeholder,
  defaultValue,
  dynamicParams,
  fileType = [],
}) => {
  const { content } = useStoreState((state) => state.campaignModule)
  const handleChange = (val) => {
    if (name) onChange(name, val)
    else onChange(val)
  }

  return useMemo(() => {
    switch (type) {
      case 'string':
        return (
          <Input
            defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            allowClear
            onChange={(e) => handleChange(e.target.value)}
          />
        )
      case 'text_area':
        return (
          <TextArea
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
          />
        )
      case 'image':
        return <ImageField onChange={handleChange} value={value} fieldName={name} />
      case 'images':
      case 'videos':
      case 'documents':
        return (
          <UploadField onChange={handleChange} value={value} fieldName={name} fileType={fileType} />
        )
      case 'select':
        return (
          <Select
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            options={options?.map((option) => ({ value: option.code, label: option.name }))}
          />
        )
      case 'checkbox':
        return (
          <Checkbox checked={!!value} defaultChecked={!!defaultValue} onChange={handleChange} />
        )
      case 'dependent_selects':
        const formattedOptions =
          options?.map((option) => ({
            value: option.type,
            label: option.label,
            tooltip: option.tooltip,
            sub_options: option.sub_options?.map((sub_option) => ({
              value: sub_option.code,
              label: sub_option.name,
            })),
          })) || []
        return (
          <DependentSelects
            title="Button"
            value={value || [{ option: formattedOptions[0].value, sub_option: '' }]}
            options={formattedOptions}
            onChange={(value) => handleChange(value)}
          />
        )
      case 'button_selects':
        const formattedButtonOptions =
          options?.map((option) => ({
            value: option.type,
            label: option.label,
            tooltip: option.tooltip,
            sub_options: option.sub_options?.map((sub_option) => ({
              value: sub_option.code,
              label: sub_option.name,
            })),
          })) || []
        return (
          <ButtonSelects
            title="Button"
            value={value || [{ option: formattedButtonOptions[0].value, sub_option: '' }]}
            options={formattedButtonOptions}
            onChange={(value) => handleChange(value)}
          />
        )
      case 'button_selects_deeplink':
        const formattedButtonOptionsDeeplink =
          options?.map((option) => ({
            value: option.type,
            label: option.label,
            tooltip: option.tooltip,
            sub_options: option.sub_options?.map((sub_option) => ({
              value: sub_option.code,
              label: sub_option.name,
            })),
          })) || []
        return (
          <ButtonSelectsDeepLink
            title="Button"
            value={
              content?.cta ||
              value || [{ option: formattedButtonOptionsDeeplink[0].value, sub_option: '' }]
            }
            options={formattedButtonOptionsDeeplink}
            onChange={(value) => handleChange(value)}
          />
        )
      case 'product_selects':
        return (
          <ProductField
            onAddProduct={(product) => {
              handleChange([...(value || []), product])
            }}
            onRemoveProduct={(product) => {
              handleChange(value.filter((item) => item.ProductId !== product.ProductId))
            }}
            value={value || []}
          />
        )
      case 'dynamic_content':
        return (
          <DynamicContent
            defaultValue={defaultValue}
            value={value?.replace(/<br >/g, '\n')}
            placeholder={placeholder}
            onChange={handleChange}
            fieldName={name}
            dynamicParams={dynamicParams}
          />
        )
      default:
        return <Input type="hidden" />
    }
  }, [name, options, type, value, onChange, placeholder, dynamicParams])
}

export default FormItem
